import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Grid, ControlLabel } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import RetailerIntro from './retailer/RetailerIntro';
import RetailerTab from './retailer/RetailerTab';
import './dashboard.scss';
import Helpers from 'utils/helpers';
import { withRouter } from 'react-router-dom';
import thunkFetchContactInfo from 'dashboard/thunks/thunkFetchContactInfo';
import thunkFetchRetailersList from 'dashboard/thunks/thunkFetchRetailerScrappingInfo';
import { setActiveMonth } from 'dashboard/actions/month';
import { omni_axios_instance } from 'utils/axios';
import { setRefreshExportHistoryFlag } from 'dashboard/actions/common';

import uniqid from 'uniqid';
import queryString from 'querystringify';
import _ from 'lodash';

class RetailerView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      oldMonths: [],
      nextScan: ''
    };
    this.onPeriodSelectChange = this.onPeriodSelectChange.bind(this);
    this.getPeriodSelect = this.getPeriodSelect.bind(this);
    this.filterOptionsData = this.filterOptionsData.bind(this);
  }

  getRetailerScrappingInfo(mId) {
    this.props.fetchRetailerScrappingInfo({ mId });
  }

  componentWillReceiveProps(nextProps) {
    let { months: prevMonths } = this.props;
    let { months } = nextProps;

    // If old months are not same as new one
    // Which means retailer is changed or its page reload
    // We shouldn't check array length, instead do deep compare for collection
    let isRetailerChanged = prevMonths.length !== months.length;
    if (!isRetailerChanged) {
      return;
    }

    // Still data is not available from API / no data
    if (isEmpty(months)) {
      return;
    }
    // This means if there is retailer selected and period is not selected
    // by default we have to select first month default
    let { mId } = nextProps.match.params;
    console.log({ mId });
    if (!mId) {
      mId = months[0]._id;
    }
    let month = find(months, {
      _id: mId
    });
    this.props.setActiveMonth(month);
    this.getRetailerScrappingInfo(mId);
    this.props.doFetchContactInfo();
  }

  /*  componentDidUpdate(prevProps, prevState) {
    setTimeout(() => {
      Helpers.scrollToTop();
    }, 300);
  } */

  onPeriodSelectChange(event) {
    let value = event.target.value;
    let { retailer, months } = this.props;
    // Means default selection with value '0'
    if (!value) {
      // new path without month
      let path = `/dashboard/retailer/${retailer._id}`;
      this.props.history.push(path);
      return;
    }
    let month = find(months, {
      _id: value
    });
    this.props.setActiveMonth(month);
    this.getRetailerScrappingInfo(value);
    this.props.doFetchContactInfo();
    // new path with month
    let path = `/dashboard/retailer/${retailer._id}/period/${value}`;
    this.props.history.push(path);
  }

  getPeriodSelect() {
    let { months, month, retailerScrappingInfoData } = this.props;
    let { data: retailerScrappingInfo } = retailerScrappingInfoData;
    let timeZone = new Date().getTimezoneOffset();

    if (isEmpty(months)) {
      return null;
    }

    let opts = [];
    forEach(months, function(val, key) {
      opts.push({
        value: val._id,
        label: moment(new Date(`${val.value}`))
          .add(timeZone, 'minutes')
          .format('MMMM DD, YYYY')
      });
    });

    let selectedPeriod = 0;
    if (!isEmpty(month)) {
      selectedPeriod = month._id;
    }
    let nextScan = Helpers.getNextScan(
      months,
      month._id,
      retailerScrappingInfo.nextScanDay
    );
    let previousScan = Helpers.getPreviousScan(months, month._id);
    return (
      <div className="retailer-period-select-container">
        <div className="title-col">
          <ControlLabel>Period:</ControlLabel>
        </div>
        <div className="select-col">
          <select
            name="period-select"
            className="form-control"
            value={selectedPeriod}
            onChange={this.onPeriodSelectChange}
          >
            {opts.map((option, key) => {
              let optionKey = uniqid('select_');
              return (
                <option key={optionKey} value={option.value}>
                  {option.label}
                </option>
              );
            })}
          </select>

          <div className="next-scanned-label">
            <p className="next-scan">
              Next Scan:&nbsp;
              {nextScan}
            </p>
            <p className="previous-scan">Previous Scan: {previousScan}</p>
          </div>
        </div>
      </div>
    );
  }
  filterOptionsData(item) {
    let content = '';
    if (_.isEmpty(item.brand_retailer)) {
      return content;
    }
    let { webSiteType } = item.brand_retailer;

    if (webSiteType === 'retailerWithoutProducts') {
      content = Helpers.checkRetailerModule({
        isModule: false,
        imgType: 'light'
      });
    } else if (
      webSiteType === 'retailerWithModule' ||
      webSiteType === 'retailerWithProductsandModule'
    ) {
      content = Helpers.checkRetailerModule({
        isModule: true,
        brandName: item.brand_name,
        imgType: 'light',
        abcWithProduct: webSiteType === 'retailerWithProductsandModule'
      });
    } else {
      content = '';
    }
    return content;
  }
  exportRetailerInfo = exportData => {
    let { filters, retailerScrappingInfoData } = this.props;
    let { data: retailerScrappingInfo } = retailerScrappingInfoData;
    let query = {
      ...filters.atts,
      page: filters.page,
      limit: filters.limit
    };
    query = queryString.stringify(query);
    let api =
      exportData && exportData === 'oh_mapped'
        ? `https://api.pricescraper.net/exportFile?retailer_brand_scraping=${retailerScrappingInfo._id}&page=1&limit=25&oh_map_id=true`
        : exportData && exportData === 'oh_not_mapped'
        ? `https://api.pricescraper.net/exportFile?retailer_brand_scraping=${retailerScrappingInfo._id}&page=1&limit=25&oh_map_id=false`
        : `/exportFile?retailer_brand_scraping=${retailerScrappingInfo._id}&${query}`;
    omni_axios_instance
      .get(
        // `/exportFile?retailer_brand_scraping=${retailerScrappingInfo._id}&${query}`
        api
      )
      .then(response => {
        window.location = response.data.link;
        /*
        setTimeout(() => {
          this.props.setRefreshExportHistoryFlag(true);
        }, 1000);
        */
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  render() {
    let {
      retailer: selectedRetailer,
      month: selectedPeriod,
      months,
      retailerScrappingInfoData
    } = this.props;

    let {
      data: retailerScrappingInfo,
      loading: isLoading
    } = retailerScrappingInfoData;
    let htmlContent = '';
    if (isLoading) {
      htmlContent = (
        <div>
          {this.getPeriodSelect()}
          <div className="RetailerView-Loader">
            <i className="fa fa-spin fa-spinner fa-2x fa-fw" />
          </div>
        </div>
      );
    } else {
      htmlContent = (
        <div>
          {this.getPeriodSelect()}
          <RetailerIntro
            retailerScrappingInfo={retailerScrappingInfo}
            months={months}
            selectedPeriod={selectedPeriod}
            exportRetailerInfo={this.exportRetailerInfo}
          />
          <RetailerTab
            retailer={selectedRetailer}
            retailerScrappingInfo={retailerScrappingInfo}
            exportRetailerInfo={this.exportRetailerInfo}
          />
        </div>
      );
    }

    return (
      <div id="retailerView" className="page">
        <Grid fluid>
          <h3 className="selected-retailer-name">
            {selectedRetailer.name}{' '}
            {this.filterOptionsData(retailerScrappingInfo)}
          </h3>

          {htmlContent}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    retailer: state.retailer,
    filters: state.products.filters,
    retailers: state.retailers.items,
    months: state.months.items,
    month: state.month,
    retailerScrappingInfoData: state.retailerScrappingInfoData
  };
};

/* const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setActiveMonth,
      doFetchContactInfo,
      setRefreshExportHistoryFlag
    },
    dispatch
  );
}; */

const mapDispatchToProps = dispatch => {
  return {
    setActiveMonth: value => {
      dispatch(setActiveMonth(value));
    },
    doFetchContactInfo: value => dispatch(thunkFetchContactInfo()),
    setRefreshExportHistoryFlag: value =>
      dispatch(setRefreshExportHistoryFlag(value)),
    fetchRetailerScrappingInfo: mId => {
      dispatch(thunkFetchRetailersList(mId));
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RetailerView)
);

import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Grid, Modal } from 'react-bootstrap';
import RetailerContactForm from 'dashboard/components/Forms/RetailerContactForm';

class RetailerContactModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.show || false
    };
    this.onModalHide = this.onModalHide.bind(this);
  }

  onModalHide() {
    let { callback } = this.props;
    this.setState(
      {
        showModal: false
      },
      () => {
        callback && callback();
      }
    );
  }

  render() {
    let { multiple, source, month, retailer, pId } = this.props;
    let { showModal } = this.state;
    return (
      <div className="modal-container">
        <Modal
          animation={false}
          autoFocus={false}
          enforceFocus={false}
          className="RetailerContactModal"
          show={showModal}
          onHide={() => this.onModalHide()}
        >
          <Modal.Header closeButton>
            <Modal.Title className>
              {this.props.type
                ? `Download Data and Imagery`
                : `Contact ${retailer.name}`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Grid fluid>
              <RetailerContactForm
                multiple={multiple}
                rId={retailer._id}
                pId={pId}
                source={source}
                callback={this.onModalHide}
                links={this.props.links}
                subject={this.props.subject}
                type={this.props.type}
              />
            </Grid>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    month: state.month,
    retailer: state.retailer
  };
};

export default withRouter(connect(mapStateToProps, null)(RetailerContactModal));

import React from 'react';
import { Grid, Row, Col, Table } from 'react-bootstrap';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// react component that creates a switch button that changes from on to off mode
import cx from 'classnames';
import Card from 'dashboard/components/Card/Card.jsx';
import OmniSwitch from 'dashboard/components/OmniSwitch';
import ImageLoader from 'dashboard/components/ImageLoader';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import Helpers from 'utils/helpers';
import ProductPagination from 'dashboard/ProductPagination';
import {
  doFetchProducts,
  doFetchProductsUpdate,
  doFetchProductsFullfilled,
  doFetchProductsError
} from 'dashboard/actions/products';
import { addBasketProduct } from '../../actions/retailerBasket';
import { omni_axios_instance } from './../../../utils/axios';
import RetailerContactModal from 'dashboard/components/RetailerContactModal';
import FileUploadModal from '../FileUploadModal';
import $ from 'jquery';
import ImageModal from '../ImageModal';
import { toastr } from 'react-redux-toastr';
import thunkFetchProducts from 'dashboard/thunks/thunkFetchProducts';

const retailerProductThArray = [
  {
    name: 'Flag',
    sort: false
  },
  {
    name: 'Product Image',
    sort: false
  },
  {
    name: 'Name',
    sort: 'name',
    order: 'asc',
    isActive: false
  },
  {
    name: 'Price',
    sort: 'price',
    order: 'asc',
    isActive: false
  },
  {
    name: 'Retailer Sku',
    sort: 'style_number',
    order: 'asc',
    isActive: false
  },
  {
    name: 'Brand Sku',
    sort: 'style_number',
    order: 'asc',
    isActive: false
  },
  {
    name: 'Details',
    sort: false
  }
];

class RetailerProductsTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tableHeadOpts:
        this.props.tabName && ['all_oh', 'oh'].includes(this.props.tabName)
          ? [
              ...retailerProductThArray.filter(
                prod =>
                  ![
                    'Name',
                    'Retailer Sku',
                    'Brand Sku',
                    'Details',
                    'Price'
                  ].includes(prod.name)
              ),
              {
                name: 'Serial #',
                sort: false
              },
              {
                name: 'Inventory ID',
                sort: false
              },
              {
                name: 'Description',
                sort: false
              },
              {
                name: 'Price',
                sort: 'price',
                order: 'asc',
                isActive: false
              }
            ]
          : retailerProductThArray,
      _id: '',
      map_id: '',
      showLabel: true,
      showRetailerContactModal: false,
      links: '',
      showFileModal: false,
      showImageModal: false,
      imageUrl: '',
      searchValue: ''
    };
    this.createTableData = this.createTableData.bind(this);
    this.setFlaggedItems = this.setFlaggedItems.bind(this);
    this.doSort = this.doSort.bind(this);
    this.getSort = this.getSort.bind(this);
    this.updateProductMapID = this.updateProductMapID.bind(this);
    this.openRetailerContactModal = this.openRetailerContactModal.bind(this);
    this.onRetailerContactModalClose = this.onRetailerContactModalClose.bind(
      this
    );
    this.handleAssetsPush = this.handleAssetsPush.bind(this);
    this.handleFilterSelected = this.handleFilterSelected.bind(this);
    this.handleShowFileModal = this.handleShowFileModal.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.handleImageModal = this.handleImageModal.bind(this);
    this.handleSearchValue = this.handleSearchValue.bind(this);
    this.getSearchResults = this.getSearchResults.bind(this);
  }

  async getSearchResults(value) {
    let { _id } = this.props.retailerScrappingInfoData.data;
    let { tabName } = this.props;
    if (value) {
      this.props.doFetchProducts({ atts: '' });
      let apiEndpoint =
        tabName === 'all_oh'
          ? `https://api.pricescraper.net/getOnhandData?retailer_brand_scraping=${_id}&page=1&limit=25&&flag=all_oh&search=${value}`
          : `https://api.pricescraper.net/retailerBrandProductScrapings?retailer_brand_scraping=${_id}&page=1&limit=25&atts[page]=1&search=${value}`;
      await omni_axios_instance
        .get(apiEndpoint)
        .then(response => {
          this.props.doFetchProductsFullfilled(response);
          console.log({ products: response });
        })
        .catch(error => {
          console.log({ productsError: error });
          this.props.doFetchProductsError(error.xhr);
        });
    } else {
      this.props.thunkFetchProducts({
        atts:
          tabName === 'all_oh'
            ? tabName
            : {
                page: 1
              }
      });
    }
  }

  handleSearchValue(value) {
    this.setState({ searchValue: value });
  }

  handleImageModal(value, url) {
    this.setState({ showImageModal: value, imageUrl: url });
  }

  async getProducts(value) {
    let { _id } = this.props.retailerScrappingInfoData.data;
    this.props.doFetchProducts(value);
    let apiEndpoint =
      value.atts === 'all'
        ? `/retailerBrandProductScrapings?retailer_brand_scraping=${_id}&page=1&limit=25&atts[page]=1`
        : `/retailerBrandProductScrapings?retailer_brand_scraping=${_id}&page=1&limit=25&atts[page]=1&&oh_map_id=${
            value.atts === 'oh_mapped' ? true : false
          }`;
    await omni_axios_instance
      .get(apiEndpoint)
      .then(response => {
        console.log({ products: response });
        this.props.doFetchProductsFullfilled(response);
      })
      .catch(error => {
        console.log({ productError: error });
        this.props.doFetchProductsError(error.xhr);
      });
  }

  handleShowFileModal(value) {
    this.setState({ showFileModal: value });
  }

  handleFilterSelected(e) {
    $('.export-btn.pull-right')
      .children()
      .removeClass('active');
    e.target.className = 'active';
  }

  async handleAssetsPush() {
    let { _id } = this.props.retailerScrappingInfoData.data;
    let { tabName } = this.props;
    let flag =
      tabName === 'all_oh'
        ? 'all_oh'
        : tabName === 'oh'
        ? 'oh_data'
        : tabName === 'oh_scraped'
        ? 'oh_sd_data'
        : '';
    await omni_axios_instance
      .get(
        `https://api.pricescraper.net/getOnhandData?retailer_brand_scraping=${_id}&page=1&limit=100&&flag=${flag}&exportFlag=true`
      )
      .then(response => {
        console.log('export email response', response);
        if (response.status === 200) {
          // let message = `<p>Hi,</p><p> Here is data:</p><p>File: ${response.data.s3ExcelLink}</p><p>Images: url ${response.data.s3ZipLink}</p><p>NOTE: these links will expire within 7 days, so please make sure to pull the assets ASAP.</p><p>Thanks,</p>`;
          // let message = `Hi,\nHere is data:File: ${response.data.s3ExcelLink}.\nImages: url ${response.data.s3ZipLink}\n.NOTE: these links will expire within 7 days, so please make sure to pull the assets ASAP.\nThanks,`;
          let message = `<p>Hi,</br>Here is data:</br>File:<a href=${response.data.s3ExcelLink}>Click here to download</a>.</br>Images: <a href=${response.data.s3ZipLink}>Click here to download</a>.</br>NOTE: these links will expire within 7 days, so please make sure to pull the assets ASAP.</br>Thanks,</p>`;
          this.setState({ links: message });
        }
      })
      .catch(err => {
        console.log('product update err', err);
      });
  }

  async openRetailerContactModal() {
    await this.handleAssetsPush();
    this.setState({
      showRetailerContactModal: true
    });
  }

  onRetailerContactModalClose() {
    this.setState({
      showRetailerContactModal: false
    });
  }
  async updateProductMapID(_id, map_id) {
    let { products, currentCount, totalCount } = this.props;
    let payload = { oh_map_id: map_id };
    console.log('product update payload', payload);
    await omni_axios_instance
      .put(
        `https://api.pricescraper.net/retailerBrandProductScrapings/${_id}`,
        payload
      )
      .then(response => {
        console.log('product update response', response);
        if (response.status === 200) {
          this.props.doFetchProductsUpdate({
            _id,
            map_id,
            dataUpdated: { products, currentCount, totalCount }
          });
        }
      })
      .catch(err => {
        console.log('product update err', err);
      });
  }

  setFlaggedItems(params) {}

  createTableData() {
    let {
      products: productsList,
      tabName,
      retailer,
      retailerBasket
    } = this.props;

    if (_.isEmpty(productsList)) {
      return (
        <tr key={'0'}>
          <td className="text-center" colSpan="6">
            <div>No product(s) found!</div>
          </td>
        </tr>
      );
    }

    var tableRows = [];
    // productsList.length !== 0 &&
    productsList.forEach((prop, key) => {
      let currentRetailerId = prop.retailer_brand_scraping;
      tableRows.push(
        <tr key={key} className="RetailerProductsTable-Row">
          <td className="text-center">
            {!this.props.type && (
              <OmniSwitch
                callback={this.setFlaggedItems}
                isFlagged={prop.isFlagged}
                rId={currentRetailerId}
                productName={prop.name}
                id={prop._id}
              />
            )}
            {!this.props.tabName ||
            !['all_oh', 'oh'].includes(this.props.tabName) ? (
              this.state._id === prop._id && !this.state.showLabel ? (
                <input
                  type="text"
                  value={this.state.map_id}
                  placeholder="Enter Map Id.."
                  onChange={e => {
                    console.log('e.target', e.target.value);
                    let value = e.target.value;
                    this.setState(prevState => ({
                      ...prevState,
                      map_id: value
                    }));
                  }}
                  onKeyUp={e => {
                    if (e.key === 'Enter') {
                      let { value } = e.target;
                      this.updateProductMapID(prop._id, value);
                      this.setState({ showLabel: true });
                    }
                  }}
                />
              ) : (
                <label
                  onClick={() => {
                    this.setState(prevState => ({
                      ...prevState,
                      _id: prop._id,
                      map_id: prop.oh_map_id ? prop.oh_map_id : '',
                      showLabel: false
                    }));
                  }}
                >
                  {prop.oh_map_id ? prop.oh_map_id : 'Enter map id...'}
                </label>
              )
            ) : (
              <></>
            )}
            {['all_oh', 'oh', 'oh_scraped'].includes(this.props.tabName) &&
            retailerBasket.findIndex(
              item =>
                item.customerId === retailer.optId &&
                item.serialNo === prop.serialNo
            ) === -1 ? (
              <span
                className="product_add"
                // onClick={(e) => this.props.addBasketProduct(prop, "Add")}
                onClick={e => {
                  let index = retailerBasket.findIndex(
                    item =>
                      item.customerId === retailer.optId &&
                      item.serialNo === prop.serialNo
                  );
                  if (index !== -1) {
                    toastr.success('Already Added!');
                  } else {
                    this.props.addBasketProduct(retailer.optId, prop);

                    toastr.success('Product Added!');
                  }
                }}
              >
                <i className="fa fa-plus" />
              </span>
            ) : (
              <></>
            )}
          </td>
          <td>
            <div className="img-container Thumbnail-Image margin-auto">
              {!tabName && (
                <Link
                  to={`/dashboard/retailer/${currentRetailerId}/product/${prop._id}`}
                  className="color-black"
                >
                  <ImageLoader
                    openInNewTab={false}
                    zoomable={false}
                    image={
                      prop.image ||
                      (prop.imageUrl && prop.imageUrl.split('|')[0])
                    }
                    className="img-responsive"
                  />
                </Link>
              )}
              {tabName && (
                <div
                  className="color-black"
                  onClick={() => {
                    this.handleImageModal(
                      true,
                      prop.image ||
                        (prop.imageUrl && prop.imageUrl.split('|')[0])
                    );
                  }}
                >
                  <ImageLoader
                    openInNewTab={false}
                    zoomable={false}
                    image={
                      prop.image ||
                      (prop.imageUrl && prop.imageUrl.split('|')[0])
                    }
                    className="img-responsive"
                  />
                </div>
              )}
            </div>
          </td>
          {!['all_oh', 'oh'].includes(this.props.tabName) ||
          !this.props.tabName ? (
            <td>
              <Link
                className={classNames({
                  'color-green': prop.type === 'change'
                })}
                to={`/dashboard/retailer/${currentRetailerId}/product/${prop._id}`}
              >
                {prop.name}
              </Link>
            </td>
          ) : (
            <></>
          )}
          {!['all_oh', 'oh'].includes(this.props.tabName) ||
          !this.props.tabName ? (
            <td>{Helpers.setProductPrice(prop.price)}</td>
          ) : (
            <></>
          )}
          {!['all_oh', 'oh'].includes(this.props.tabName) ||
          !this.props.tabName ? (
            <td>{prop.style_number}</td>
          ) : (
            <></>
          )}
          {!['all_oh', 'oh'].includes(this.props.tabName) ||
          !this.props.tabName ? (
            <td>
              {(prop.product && prop.product.sku) || prop.brand_sku || 'N/A'}
            </td>
          ) : (
            <></>
          )}

          {!['all_oh', 'oh'].includes(this.props.tabName) ||
          !this.props.tabName ? (
            <td>
              <Link
                to={`/dashboard/retailer/${currentRetailerId}/product/${prop._id}`}
              >
                Link
              </Link>
            </td>
          ) : (
            <></>
          )}
          {['all_oh', 'oh'].includes(this.props.tabName) ? (
            <>
              <td>{prop.serialNo}</td>
              <td>{prop.inventoryId}</td>
              <td>{prop.styleName}</td>
              <td>{Helpers.setProductPrice(prop.price)}</td>
            </>
          ) : (
            <></>
          )}
        </tr>
      );
    });

    return tableRows;
  }

  doSort(sortBy, oldSortOrder) {
    if (!sortBy) {
      return;
    }
    let { tableHeadOpts } = this.state;
    // get new order
    let newSortOrder = oldSortOrder === 'asc' ? 'desc' : 'asc';
    tableHeadOpts = tableHeadOpts.map(x => {
      if (x.sort === sortBy) {
        return {
          ...x,
          order: newSortOrder
        };
      }
      // else we have to reset other columns order
      // to `asc`
      return {
        ...x,
        order: 'asc'
      };
    });
    this.setState(
      {
        tableHeadOpts
      },
      () => {
        let sort = `${sortBy};${newSortOrder}`;
        // let sort = this.getSort();
        this.props.doFetchProducts({
          sort,
          page: this.props.page,
          IS_SORT_FLAG: true
        });
      }
    );
  }

  getSort() {
    let { tableHeadOpts } = this.state;
    let sortingProps = [];
    tableHeadOpts.forEach((prop, i) => {
      if (!prop.sort) {
        return;
      }
      sortingProps.push(`${prop.sort};${prop.order}`);
    });
    return sortingProps;
  }

  render() {
    let { isLoading, products, retailer, totalCount, tabName } = this.props;
    let {
      tableHeadOpts,
      showRetailerContactModal,
      showFileModal,
      showImageModal,
      imageUrl,
      searchValue
    } = this.state;
    // console.log("this.state", this.state);

    let thHtml = tableHeadOpts.map((prop, key) => {
      let sortIndicator = '';
      if (prop.sort) {
        let sortIndicatorClassNames = classNames({
          col: true,
          fa: true,
          sort: true,
          'fa-sort-asc': prop.order === 'asc',
          'fa-sort-desc': prop.order === 'desc'
        });
        sortIndicator = (
          <i className={sortIndicatorClassNames} aria-hidden="true" />
        );
      }

      let thClassName = classNames({
        thRow: true,
        'Sorting-Enabled': prop.sort
      });

      return (
        <th
          className={thClassName}
          key={`pdtr-${key}`}
          onClick={() => this.doSort(prop.sort, prop.order)}
        >
          <div className="thRow-Inner">
            <span className="col">{prop.name}</span>
            {sortIndicator}
          </div>
        </th>
      );
    });

    let tbHtml = '';
    if (isLoading) {
      tbHtml = (
        <tr className="Products-Loader">
          <td colSpan="6">
            <i className="fa fa-spin fa-spinner fa-2x fa-fw" />
          </td>
        </tr>
      );
    } else {
      tbHtml = this.createTableData();
    }

    return (
      <div className="product-table-data">
        <div className="export-btn pull-right">
          {['all_oh', 'products'].includes(tabName) ? (
            <input
              className="form-control"
              value={searchValue}
              type={'search'}
              placeholder="Type to search ..."
              onChange={e => this.handleSearchValue(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  // console.log("searchValue : ", searchValue);
                  this.getSearchResults(searchValue);
                }
              }}
            />
          ) : (
            <></>
          )}
          <label>{totalCount} Items Found</label>
          {this.props.filter && (
            <>
              <button
                className="active"
                onClick={e => {
                  this.handleFilterSelected(e);
                  this.getProducts({ atts: 'all' });
                  this.props.handleExportData('all');
                }}
              >
                All
              </button>
              <button
                onClick={e => {
                  this.handleFilterSelected(e);
                  this.getProducts({ atts: 'oh_mapped' });
                  this.props.handleExportData('oh_mapped');
                }}
              >
                Mapped
              </button>
              <button
                onClick={e => {
                  this.handleFilterSelected(e);
                  this.getProducts({ atts: 'oh_not_mapped' });
                  this.props.handleExportData('oh_not_mapped');
                }}
              >
                NOT Mapped
              </button>
              <button
                onClick={e => {
                  this.handleShowFileModal(true);
                }}
              >
                IMPORT
              </button>
            </>
          )}
          <button
            id="productlist-export-btn"
            className={cx({
              disabled: products.length === 0,
              'btn btn-default': true
            })}
            onClick={() => {
              if (['all_oh', 'oh', 'oh_scraped'].includes(this.props.tabName)) {
                this.openRetailerContactModal();
              } else {
                products.length &&
                  this.props.exportRetailerInfo(this.props.exportData);
              }
            }}
          >
            export
            <i className="fa fa-file-excel-o" />
          </button>
        </div>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                textCenter
                tableFullWidth
                content={
                  <div>
                    <ProductPagination
                      productTableList={true}
                      filters={this.props.filters}
                      handleExportData={this.props.handleExportData}
                      exportData={this.props.exportData}
                    />
                    <Table
                      responsive
                      className="RetailerProductsTable table-bigboy"
                    >
                      <thead className="RetailerProductsTableHead">
                        <tr>{thHtml}</tr>
                      </thead>
                      <tbody className="RetailerProductsTableBody">
                        {tbHtml}
                      </tbody>
                    </Table>
                    <ProductPagination />
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
        {showRetailerContactModal && (
          <RetailerContactModal
            retailer={retailer}
            products={products}
            show={showRetailerContactModal}
            callback={this.onRetailerContactModalClose}
            multiple={true}
            source={'brand'}
            links={this.state.links}
            subject={this.props.subject}
            type={this.props.type}
          />
        )}
        {showFileModal && (
          <FileUploadModal
            show={this.state.showFileModal}
            hide={this.handleShowFileModal}
          />
        )}
        {tabName && showImageModal && (
          <ImageModal
            show={showImageModal}
            url={imageUrl}
            handleImageModal={this.handleImageModal}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  // console.log({ state });
  return {
    products: state.products.items,
    isLoading: state.products.isLoading,
    page: state.products.filters.page,
    totalCount: state.products.totalProducts,
    currentCount: state.products.currentProducts,
    retailerScrappingInfoData: state.retailerScrappingInfoData,
    retailer: state.retailer,
    retailerBasket: state.retailerBasket.items
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      doFetchProducts,
      doFetchProductsUpdate,
      doFetchProductsFullfilled,
      doFetchProductsError,
      addBasketProduct,
      thunkFetchProducts
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RetailerProductsTable);

import Pages from '../Pages';
import Dash from 'dashboard/Dash.jsx';
import Privacypolicy from 'privacypolicy/Privacypolicy.jsx';
import Admin from 'admin/index.js';
import AdminConfirm from 'admin/confirm.js';
import B2BRetailerView from '../dashboard/B2BRetailerView';

var indexRoutes = [
  { path: '/admin/confirm', name: 'AdminConfirm', component: AdminConfirm },
  { path: '/dashboard', name: 'Dash', component: Dash },
  {
    path: '/b2bReatilerView/:rId',
    name: 'b2bReatilerView',
    component: B2BRetailerView
  },
  { path: '/privacypolicy', name: 'Privacypolicy', component: Privacypolicy },
  { path: '/admin', name: 'Admin', component: Admin },
  { path: '/', name: 'Page', component: Pages }
];

export default indexRoutes;

// @flow
import React from 'react';
// import ReactDOM from 'react-dom';
import { hydrate, render } from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import './index.scss';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import NProgress from 'nprogress';
import store, { history } from './configureStore';
import { Route, Switch, Redirect } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import queryString from 'querystringify';
import { GA_TRACKING_ID } from 'utils/constants';
import ReactGA from 'react-ga';
import 'core-js/stable';
import storeData from 'store';

// import 'react-app-polyfill/ie9';

// Routes
import indexRoutes from './routes/index.jsx';
import './assets/css/bootstrap.min.css';
import './assets/css/light-bootstrap-dashboard.css';
import 'font-awesome/css/font-awesome.min.css';
import './assets/css/demo.css';
import './assets/css/pe-icon-7-stroke.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './assets/css/nprogress.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Auth from 'utils/auth';
import _ from 'lodash';
import LoginPage from './login/LoginPage';
ReactGA.initialize(GA_TRACKING_ID, {
  debug: false
});

// Store histroy on window
window.appHistory = history;

ReactGA.set({ page: window.location.pathname });
ReactGA.pageview(window.location.pathname);

history.listen((location, action) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

let currentValue;
function handleChange() {
  let previousValue = currentValue;
  // console.log('l1 previousValue =', previousValue);
  const state = store.getState();
  currentValue = state.router.location.pathname;
  // console.log('l2 currentValue =', currentValue);
  if (previousValue && previousValue !== currentValue) {
    // get existing query
    let q1 = queryString.parse(state.router.location.search);
    // get query from store
    let q2 = queryString.parse(state.common.searchString);
    // Merge them
    let q = Object.assign(q1, q2);
    window.appHistory.replace({
      search: queryString.stringify(q)
    });
  }
}
store.subscribe(handleChange);

// NProgress
NProgress.configure({
  showSpinner: true,
  minimum: 0.1
});

function scrollToTop() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

const rootElement = document.getElementById('root');
let defaultRoute;
const user = Auth.getUser();
const isLogin = () => {
  if (_.isEmpty(user)) {
    return false;
  }
  // console.log('user', user);
  // console.log('Auth.isAdmin()', Auth.isAdmin());
  if (Auth.isAdmin()) {
    defaultRoute = '/admin/confirm';
  } else {
    ReactGA.ga('set', 'userId', user.email);
    ReactGA.ga('set', 'dimension1', user.brand.slug);
    // Setting the userId doesn't send data to Google Analytics.
    // You must also use a pageview or event to send the data.
    ReactGA.event({
      category: 'authentication',
      action: 'logged in',
      label: user.email
    });
    defaultRoute = '/dashboard/summary';
  }
  return true;
};
const App = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <div className="App" id="App">
          <Switch>
            {window.location.href.includes('b2bReatilerView') && (
              <Redirect from="/" exact to="/b2bReatilerView/:rId" />
            )}
            {isLogin() && <Redirect from="/" exact to={defaultRoute} />}
            {indexRoutes.map((prop, key) => {
              return (
                <Route path={prop.path} component={prop.component} key={key} />
              );
            })}
          </Switch>
          <span onClick={scrollToTop} className="fab-icon">
            <i className="fa fa-arrow-up" aria-hidden="true" />
          </span>
          <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
          />
        </div>
      </ConnectedRouter>
    </Provider>
  );
};
// https://github.com/stereobooster/react-snap
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

registerServiceWorker();

import { omni_axios_instance } from 'utils/axios';
import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import {
  doFetchProducts,
  doFetchProductsFullfilled,
  doFetchProductsError
} from 'dashboard/actions/products';

export default function thunkFetchProducts(action) {
  return function(dispatch, getState) {
    dispatch(doFetchProducts(action));
    // console.log({
    //   action
    // });
    const state = getState();
    let { filters: oldAtts } = state.products;
    let obj = {};
    if (action.atts && action.atts.hasOwnProperty('atts')) {
      if (action.atts.atts.hasOwnProperty('tab')) {
        obj.tab = 'change';
      } else if (action.atts.atts.hasOwnProperty('isFlagged')) {
        obj.isFlagged = 'yes';
      } else {
        obj = {
          ...action.atts.atts.atts
        };
      }
    }
    let newAtts = Object.assign(
      {
        retailer_brand_scraping: state.month._id
      },
      oldAtts,
      action.atts,
      {
        ...obj
      }
    );
    newAtts.tab = newAtts.atts['tab'];
    if (isEmpty(newAtts.retailer_brand_scraping)) {
      return;
    }
    let searchString = qs.stringify(newAtts, {
      arrayFormat: 'brackets',
      encode: false
    });
    Object.assign(action, {
      searchString
    });
    // console.log({
    //   action
    // });
    let apiEndpoint;
    if (
      (action.atts || oldAtts.atts) &&
      (typeof action.atts === 'string' ||
        [
          'price_diff',
          'all_oh',
          'sd_data',
          'oh_data',
          'price_match',
          'oh_sd_data'
        ].includes(oldAtts.atts))
    ) {
      apiEndpoint = `/getOnhandData?retailer_brand_scraping=${
        state.month._id
      }&page=${action.page ? action.page : 1}&limit=25&&flag=${oldAtts.atts}`;
    } else {
      apiEndpoint = `/retailerBrandProductScrapings?${action.searchString}`;
    }
    return omni_axios_instance
      .get(apiEndpoint)
      .then(response => {
        // console.log("response:", response);
        dispatch(doFetchProductsFullfilled(response));
      })
      .catch(error => dispatch(doFetchProductsError(error.xhr)));
  };
}
